import React from 'react'

function Second() {
  return (
    <section className="second">
        <div className="container">
            <div className="inner-wrapper d-flex align-items-center justify-content-between mb-5">
                <div className="left-col">
                    <p className="text">
                    This social casino is designed for both beginners and experienced players, offering numerous bonuses and the opportunity to uncover secrets and hidden rewards. Each new level is a chance to perfect your skills and enjoy stunning visual effects and captivating sound. Crazy Monkey Slot is more than just a game; it's an entire experience that immerses you from the very first spin.
                    </p>
                </div>
                <div className="right-col">
                    <img src="/2_(2).jpg" alt="second img"/>
                </div>
            </div>
            <p className="text text-md">
            An attractive design and festive atmosphere in the game create a virtual celebration where every player can enjoy juicy gameplay. The social casino Crazy Monkey Slot gives you the chance to savor the sweetness of victory in a free game, while diving into a realm of excitement and fun. In this game, you will find a beacon of comfort and enjoyment, revealing secrets and hidden bonuses with each new session.
            </p>
        </div>
    </section>
  )
}

export default Second