import React from 'react'
import Footer from './Footer'
import Navigation from './Navigation'

function ContactUs() {
  return (
  <>
  <Navigation/>
    <section className="contact-us">
        <div className="container">
            <div className="inner-wrapper d-flex align-items-center justify-content-between mb-5">
                <div className="left-col">
                    <h2 className="contact-title">
                    If you have any questions, feel free to contact us!
                    </h2>
                    <p className="contact-text">
                    Contact us! We appreciate your feedback and are always here to assist you. Leave a message, and we will get back to you as soon as possible.
                    </p>
                </div>
                <div className="right-col">
                    <form action="">
                        <div className="input-wrapper">
                            <label for="name">
                            Name <span>(required)</span> 
                            </label>
                            <input type="text" name="name" id="name"/>
                        </div>
                        <div className="input-wrapper">
                            <label for="email">
                                Email <span>(required)</span> 
                            </label>
                            <input type="text" name="email" id="email"/>
                        </div>
                        <div className="input-wrapper">
                            <label for="comment">
                            Message
                            </label>
                            <textarea name="comment" id="comment"></textarea>
                        </div>
                        <button type="button">
                        Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </section>
  <Footer/>
  </>
  )
}

export default ContactUs