import React from 'react'

function Third() {
  return (
    <section className="third">
        <div className="container">
            <div className="inner-wrapper d-flex align-items-center justify-content-between mb-5">
                <div className="left-col">
                    <img src="/logo.png" alt="second img"/>
                </div>
                <div className="right-col">
                    <p className="text">
                    For those seeking visual delight, Crazy Monkey Slot offers captivating game symbols that make every spin truly exciting. The game features bright colors that bring each element of the interface to life, creating a genuinely festive atmosphere. This free social casino allows you to experience the thrill of a real casino without risking your own money.
                    </p>
                </div>
            </div>
            <p className="text text-md">
            Crazy Monkey Slot is a complete experience that will leave unforgettable impressions. Dive into the enchanting world of the social casino and enjoy every minute of the game. With support for mobile devices and computers, the game is always within reach, allowing you to enjoy it anytime. Crazy Monkey Slot is free entertainment that brings joy and excitement without leaving your home.
            </p>
        </div>
    </section>
  )
}

export default Third