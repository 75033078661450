import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const Footer=()=> {
  const [cookieIsAccepted,setCookieIsAccepted]=useState(true)
  const [ageIsAccepted,setAgeIsAccepted]=useState(true)

  useEffect(()=>{
const value=localStorage.getItem("cookie")
const ageValue=localStorage.getItem("age")
if(value)
{
  setCookieIsAccepted(false)
}
if(ageValue)
  {
    setAgeIsAccepted(false)
  }
  },[])

const acceptCookie=()=>{
  setCookieIsAccepted(false)
  localStorage.setItem("cookie",true)
}
const acceptAge=()=>{
  setAgeIsAccepted(false)
  localStorage.setItem("age",true)
}

  return (
    <>
        <footer>
    <div className="top-row">
        <div className="container">
            <p className="footer-text text">
            This website is dedicated to providing free entertainment. All games offered on our site are developed in-house and are exclusively made for entertainment purposes. They are intended for adults over the age of 18. Our social casino games do not involve real money betting and do not offer cash prizes. Success in these games should not be interpreted as an indication of success in gambling with real money. We promote responsible gaming practices and emphasize that our games are solely for entertainment.
            </p>
        </div>
    </div>
    <div className="bottom-row mt-3 mb-3">
        <div className="container d-flex align-items-center justify-content-between">
            <div className="logo-wrapper d-flex align-items-center justify-content-between">
                <a href="/">
                    <img src="/logo.png" alt="logo"/>
                </a>
                <a href="/" className="main-logo-text">
                    <p className="game-name">
                        Crazy Monkey Slot
                    </p>
                    <span>
                    Your Free Social Casino
                    </span>
                </a>
            </div>
            <div className="navigation">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><rect x="4" y="7.5" width="16" height="1.5"></rect><rect x="4" y="15" width="16" height="1.5"></rect></svg>
                <ul className="nav-list d-flex align-items-center justify-content-center">
                    <li>
                        <a href="/">
                        Home
                        </a>
                    </li>
                    <li>
                        <a href="contact-us">
                        Contact Us
                        </a>
                    </li>
                    <li>
                        <a href="privacy-policy">
                        Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href="terms-and-conditions">
                        Terms and Conditions
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
{
    cookieIsAccepted &&(
      <div className="cookie-banner" id="cookie-banner" style={{display:'flex'}}>
      <p>We use cookies to improve the performance of our website. By continuing to use the site, you agree to the use of cookies.</p>
      <button  onClick={()=>acceptCookie()}  id="accept-cookies">Submit</button>
  </div>
    )
  }
  {
    ageIsAccepted &&(
      <div className="age-check-popup" id="age-check-popup">
        <div className="popup-inner-wrapper">
            <h3 className="text-center">
            Age Verification
            </h3>
            <p className="popup-text text-center">
            You must be <span>18</span> years old to participate.
            </p>
            <button type="button" onClick={()=>acceptAge()} className="text-center popup-btn">
             Yes
            </button>
        </div>
        
    </div>
    )
  }
    </>

  );
}

export default Footer;