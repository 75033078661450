import React from 'react'

function Hero() {
  return (
    <section className="hero">
        <div className="container">
            <h1 className="text-center">
            Social casino Crazy Monkey Slot is a unique opportunity to enjoy all the excitement that a slot machine game offers
            </h1>
            <div className="inner-wrapper d-flex align-items-center justify-content-between mb-5">
                <div className="left-col">
                    <img src="/1_(2).jpg" alt="hero img"/>
                </div>
                <div className="right-col">
                    <p className="hero-text text">
                    This free entertainment allows you to dive into an enchanting world of gambling and experience the same emotions as in a real casino, without leaving your home. Developed for use on mobile devices and computers, the game is available on iOS and Android, offering users the opportunity to enjoy it anytime, anywhere.
                    </p>
                </div>
            </div>
            <p className="hero-text text text-md">
            From the very first moment, you'll be captivated by the game's festive atmosphere. Bright colors and enticing sounds create an overwhelming visual and auditory delight. The game's symbols, such as gold coins, glowing lanterns, and juicy fruits, draw you into a virtual celebration where every spin is a chance to win. Crazy Monkey Slot truly offers an unforgettable experience, making every player a part of our social community.
            </p>
        </div>
    </section>
  )
}

export default Hero